export function initCollapsible(blockSelector = "accordion-header") {

  const block = document.getElementsByClassName(blockSelector);
  if (block) {
    for(const element of block) {
      element.addEventListener("click", (event) => {
        event.preventDefault();
        element.parentNode.classList.toggle("active");
      });
    }
  }
}
