class Video {
    constructor(playerId, videoData) {
        this.playerId = playerId;
        this.videoId = videoData.videoId;
        this.height = videoData.height;
        this.width = videoData.width;
        this.player = "";
        this.tab = false;
    }
}


export const initVideoYT = () => {

    // Agrega el script de la api de YT
    let tag = document.createElement('script');
    tag.src = "https://www.youtube.com/iframe_api";
    let firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    //Genera los players.
    window.onYouTubeIframeAPIReady = () => {
        const playerInfoList = getDataIframe();
        return playerInfoList.forEach(playerInfo => {
        let newPlayer = createPlayer(playerInfo);
        // A partir de playerInfo, se encuentra el elemento iframe por id
        // y se le agrega la clase app-tabvideo si pertenece a una tab
        let playerIframe = document.getElementById(playerInfo.playerId);
        if (playerIframe && playerInfo.tab) {
            playerIframe.classList.add("app-tabvideo");
        }
        return newPlayer
    })
    }
}

// Trae los datos de los iframe. Asigna un id al player y otro al video
// Mete estos datos en la lista playerInfoList
function getDataIframe() {
    const videoContainers = document.querySelectorAll(".app-iframe iframe, .block-paragraph iframe");
    const playerInfoList = [];
    videoContainers.forEach(video => {
        const parent = video.parentNode;
        const hash = doHash();
        const playerId = `video-yt-${hash}`;
        parent.setAttribute("id", playerId);

        var videoInfo = new Video(playerId, {
            height: video.getAttribute('height'),
            width: video.getAttribute('width'),
            videoId: youtube_parser(video.getAttribute('src'))
        });
        const tabcontainer = video.closest('.tab-container');
        if (tabcontainer !== null) {
            videoInfo.tab = true;
        }
        playerInfoList.push(videoInfo)
    });
    return playerInfoList
}

const doHash = () => Math.random().toString(36).substring(7);

function youtube_parser(url) {
    let videoId = 0;
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    var match = url.match(regExp);
    if (match && match[7].length == 11) {
        videoId = match[7];
    }
    return videoId;
}

// Crea los reproductores de YT necesarios
function createPlayer(playerInfo) {
    // playerId es el id del elemento que se creó y está en el template.
    // La función onYouTubeIframeAPIReady reemplaza ese elemento por un nuevo iframe
    const playerOptions = new YT.Player(playerInfo.playerId, {
        height: playerInfo.height,
        width: playerInfo.width,
        videoId: playerInfo.videoId,
        playerVars: {
            'enablejsapi': 1,
        },
        events: {
            'onReady': onPlayerReady,
        }
    });
    return playerOptions;
}

// Esta función se usa para conseguir la llave del atributo que contiene el iframe
// Se llama en la siguiente función que inicializa los observer
function getIframeKey(object) {
    let iframeKey = "";
    for (const [key, value] of Object.entries(object)) {
        let newValue = value + '';
        if (newValue.startsWith('[object HTMLIFrame')){
            iframeKey = key;
            break;
        }
    }
    return iframeKey;
}

const playersData = [];
// La api llama a esta función cuando el reproductor está listo
function onPlayerReady(event) {
    // Se busca la clave del elemento iframe y se chequea que se haya encontrado
    // antes de inicializar los observer
    let iframeKey = getIframeKey(event.target);
    if (iframeKey !== "") {
        const iframeElement = event.target[iframeKey];
        const data = {
            playerId: iframeElement.id,
            player: event.target
        /* A cada video le adjunta un observer para detectar cuando es visible */
        /* A los videos que son de tab, les agrega también un mutationObserver */
        }
        observer.observe(iframeElement);
        playersData.push(data);
        if (iframeElement.classList.contains('app-tabvideo')) {
            mutationObserver.observe(iframeElement, {
                attributes: true,
                attributeFilter: ["class"]
            });
        }
    }
}

/* Al cambiar de tab, se remueve la clase app-tabvideo (ver tabs.js) y se pausa el video */
const mutationObserver = new MutationObserver(entries => {
    entries.forEach(entry => {
        playersData.forEach(playerActive => {
            if (entry.target && !entry.target.classList.contains('app-tabvideo')) {
                playerActive.player.pauseVideo();
            }
        })
    })
})


/* Si el video no es visible lo pausa*/
const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
        playersData.forEach(playerActive => {
            // Pausa si la página deja de ser visible
            document.addEventListener("visibilitychange", () => {
                if (document.visibilityState == 'hidden') {
                    playerActive.player.pauseVideo();
                }
            })
            // Pausa si el video no está en pantalla
            if (!entry.isIntersecting) {
                playerActive.player.pauseVideo();
            }
        })
    })
}, {
    threshold: 0.5,
    rootMargin: "-200px"
});
