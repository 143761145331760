export function initSticky() {
  const stickyMenu = document.getElementById("is-header-hero");
  const body = document.getElementsByTagName('body')[0];
  const navbar = document.getElementById("navbar");

  if (stickyMenu) {
    const stickyOffset = stickyMenu.offsetTop;
    
    window.addEventListener("scroll", function (e) {
      requestAnimationFrame(() => {
        if (window.pageYOffset > stickyOffset) {
          body.classList.add("has-navbar-white");
          navbar.classList.add("is-fixed-top");
        } else {
          body.classList.remove("has-navbar-white");
          navbar.classList.remove("is-fixed-top");
        }
      });
    });
  }
}
