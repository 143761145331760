export const initTabs = (selector = "div.block-vertical_tabs, div.block-accordion") => {
  const vtabs = document.querySelectorAll(selector);

  vtabs.forEach((el) => {
      const tabs = el.querySelectorAll(".tabs li");
      const tabsContent = el.querySelectorAll(".tab-container");
      const imgviewer = el.querySelector(".viewer");
  
      const deactivateAllTabs = () => {
        tabs.forEach((tab) => {
          tab.classList.remove("is-active");
        });
      };
  
      const hideTabsContent = () => {
        tabsContent.forEach((tabContent) => {
          tabContent.classList.remove("is-active");
          const tabVideo = tabContent.querySelector('iframe');
          if (tabVideo) {
            tabVideo.classList.remove("app-tabvideo");
          }
        });
        if (imgviewer !== null) {
          imgviewer.src = "";
          imgviewer.alt = "";
        }
      };
  
      const activateTabsContent = (tab) => {
        const tabActive = tabsContent[getIndex(tab)];
        tabActive.classList.add("is-active");
        /* En vertical tabs, selecciona el título de la tab activa
        y busca la imagen que corresponde a esta tab para rellenar el
        contenedor imgviewer */
        const tabTitle = tabActive.getAttribute('data-title');;
        const imgsrc = el.querySelector(`[data-tab="${tabTitle}"]`);
        if (imgviewer !== null && imgsrc !== null) {
          imgviewer.src = imgsrc.src;
          imgviewer.alt = imgsrc.alt;
      }
      };
  
      const getIndex = (e) => {
        return [...e.parentElement.children].indexOf(e);
      };
  
      tabs.forEach((tab) => {
        tab.addEventListener("click", () => {
          deactivateAllTabs();
          hideTabsContent();
          tab.classList.add("is-active");
          activateTabsContent(tab);
        });
      });
      if (tabs.length > 0) {
        tabs[0].click();
      }
  });


  // Scroll en el menú de tabs
  const accordion = document.querySelectorAll(selector);

  accordion.forEach((el) => {

    const leftArrow = el.querySelector('.menu-container .glide__arrow.glide__arrow--left');
    const rightArrow = el.querySelector('.menu-container .glide__arrow.glide__arrow--right');
    const menu = el.querySelector('.app-menu');

    const menuWrapperSize = el.querySelector('.tabs').offsetWidth;
    const menuSize = el.querySelector('.app-menu').offsetWidth;
    const menuInvisibleSize = Math.max(menuSize - menuWrapperSize, 0);
    const arrowSize = rightArrow.offsetWidth;
    const menuEndOffset = Math.max(menuInvisibleSize - arrowSize, 0);	
    const itemsCount = el.getElementsByClassName('app-item').length;
    const itemSize = el.getElementsByClassName('app-item')[0].offsetWidth;
    const itemsSpaceBetween = (menuSize - (itemsCount * itemSize)) / (itemsCount - 1);
    const distanceInPixels = itemSize + itemsSpaceBetween;
    const durationInMilliseconds = 500;
    let starttime = null;


    if (menuInvisibleSize === 0) {
      rightArrow.classList.add("is-hidden");
    }

    const getMenuPosition = () => {
      return parseFloat(menu.style.left) || 0;
    };

    const getScrolledDistance = () => {
      return -1 * getMenuPosition();
    };

    const checkPosition = () => {
      const menuPosition = getScrolledDistance();

      if (menuPosition <= arrowSize) {
        leftArrow.classList.add("is-hidden");
        rightArrow.classList.remove("is-hidden");
      } else if (menuPosition < menuEndOffset) {
        leftArrow.classList.remove("is-hidden");
        rightArrow.classList.remove("is-hidden");
      } else if (menuPosition >= menuEndOffset) {
        leftArrow.classList.remove("is-hidden");
        rightArrow.classList.add("is-hidden");
        }
    };

    const animateMenu = (timestamp, startingPoint, distance) => {
      const runtime = timestamp - starttime;
      let progress = runtime / durationInMilliseconds;
      progress = Math.min(progress, 1);
      let newValue = (startingPoint + (distance * progress)).toFixed(2) + 'px';
      menu.style.left = newValue;

      if (runtime < durationInMilliseconds) {
            requestAnimationFrame(function(timestamp) {
                animateMenu(timestamp, startingPoint, distance);
            })
        }
      checkPosition();
    };
    
    const animationFramesSetup = (timestamp, travelDistanceInPixels) => {
      timestamp = timestamp || new Date().getTime();
      starttime = timestamp;
      const startingPoint = getMenuPosition();
      animateMenu(timestamp, startingPoint, travelDistanceInPixels);
    };
    
    rightArrow.addEventListener('click', () => {
      requestAnimationFrame(
        timestamp => animationFramesSetup(timestamp, -1 * distanceInPixels)
      )
      }
    );

    leftArrow.addEventListener('click', () => requestAnimationFrame(
      timestamp => animationFramesSetup(timestamp, distanceInPixels)
    ));
  });
};