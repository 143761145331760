import Glide from "@glidejs/glide";

const addSlideClass = (event, glide) => {
  event.stopPropagation();
  event.preventDefault();
  const sliceHover = event.target;
  const galleryId = glide.selector;
  const gallery = document.querySelector(galleryId);

  const sliceActive = gallery.querySelector(".glide__slide--active");

  const nextTwoSiblings = sliceActive.nextElementSibling.nextElementSibling;
  const nextThreeSiblings = sliceActive.nextElementSibling.nextElementSibling.nextElementSibling;
  const lastSlice = glide.settings.perView == 4 ? nextThreeSiblings: nextTwoSiblings;

const card = sliceHover.firstElementChild.firstElementChild
  if (sliceHover == sliceActive) {
    card.classList.add('left-slide');
  }
  else if (sliceHover == lastSlice) {

    card.classList.add('right-slide');
  }
};

const removeSlideClass = (event) => {
  event.stopPropagation();
  event.preventDefault();
  const sliceHover = event.target;
  const card = sliceHover.firstElementChild.firstElementChild
  if (card.classList.contains('left-slide')) {
    card.classList.remove('left-slide');
  }
  else if (card.classList.contains('right-slide')) {
    card.classList.remove('right-slide');
  }
};


export const initNews = (selector=".slider-news") => {
  const slides = document.querySelectorAll(selector);
  if (slides.length ) {
    const glideOptions = {
      type: "slider",
      perView: 4,
      autoplay: false,
      bound: true,
      focusAt: "0",
      gap: 0,
      breakpoints: {
        1215: {
          perView: 3,
        },
        1023: {
          perView: 2,
        },
        768: {
          perView: 1,
        },
      },
    };

    slides.forEach((element) => {
      let item = element;
      let d = new Date();
      let id = "gallery-" + d.getTime();
      item.setAttribute("id", id);
      let slider = new Glide("#" + id, glideOptions);
      if (element.querySelectorAll('.glide__slide').length > 1) {
        slider.mount();
      }
      let childs = element.querySelectorAll('.glide__slide');
      if (slider.settings.perView > 2) {
        childs.forEach((c) =>
        c.addEventListener("mouseenter", function (e) {
          addSlideClass(e, slider);
        })
      );
      childs.forEach((c) =>
        c.addEventListener("mouseleave", function (e) {
          removeSlideClass(e);
        })
      );
      }

    });
  }
};


