import Glide from "@glidejs/glide";
import { initModal } from "./modal";


const glideOptions = {
  type: "carousel",
  perView: 1,
  peek: 0,
  gap: 0,
  focusAt: "center",
  animationDuration: 300,
  animationTimingFunc: "ease-in",
};

function getAll(selector) {
  return Array.prototype.slice.call(document.querySelectorAll(selector), 0);
}

const buildModal = (
  url,
  alt,
  full,
  target
) => `<div id="${target}" class="modal is-active">
        <div class="modal-background"></div>
        <div class="modal-content">
          <p class="image">
            <img src="${url}" alt="${alt}">
          </p>
        </div>
        <div class="glide__arrows" data-glide-el="controls">
          <button class="glide__arrow glide__arrow--left btn btn--rounded btn--size-modal btn--grey-dark" data-glide-dir="<"><span class="fas fa-chevron-left" role="presentation"></span> </button>
          <button class="glide__arrow glide__arrow--right btn btn--rounded btn--size-modal btn--grey-dark" data-glide-dir=">"><span class="fas fa-chevron-right" role="presentation"></span> </button>
        </div>
        <div class="modal-button">
          <a href="${full}" class="glide__download icon is-large btn-border" download="${full}">
          <span class="icon has-text-white">
              <span class="fa fa-download" aria-hidden="true"></span> 
          </span>
          </a>
        </div>

        <div class="modal-button">
          <a href="${full}" class="glide__download icon is-large btn-border" download="${full}">
          <span class="icon has-text-white">
              <span class="fa fa-download" aria-hidden="true"></span> 
          </span>
          </a>
        </div>
        <div class="close-deco">
          <button class="modal-close close-button is-large" aria-label="close">
            <div class="btn-border icon is-large" aria-label="close">
              <span class="icon has-text-white">
                  <span aria-hidden="true"></span>
              </span>
            </div>
          </button>
        </div>
      </div>`;

const doHash = () => Math.random().toString(36).substring(7);

function press(event) {
  let direction = "";
  if (event.keyCode === 39 || event.keyCode === 9) {
    event.preventDefault();
    direction = ">"
  } else if (event.keyCode === 37) {
    event.preventDefault();
    direction = "<"
  }
  return direction;
}

function updateModal(glide, direction) {
  if (direction !== "") {
    const galleryId = glide.selector;
    const gallery = document.querySelector(galleryId);
    const sliceActive = gallery.querySelector(".glide__slide--active");
    const imageModal = document.querySelector(".modal-content .image img");
    const imageFileSrc = document.querySelector(".modal a");

    const nextSlice =
      direction === ">"
        ? sliceActive.nextElementSibling
        : sliceActive.previousElementSibling;

    const value = nextSlice.querySelector(".image a").getAttribute("href");
    if (imageModal) {
      imageModal.setAttribute("src", `${value}`);
      imageFileSrc.setAttribute("href", `${value}`);
      imageFileSrc.setAttribute("download", `${value}`);
    }

    glide.go(direction);
  }

}

const slideHandler = (event, glide) => {
  event.stopPropagation();
  event.preventDefault();
  const target = `modal-${doHash()}`;
  const rootEl = document.documentElement;
  const element = event.currentTarget;

  const alt = element.querySelector("img").getAttribute("title");
  const url = element.getAttribute("href");
  const full = element.dataset.originalImage;
  element.dataset.target = target;

  const wrapper = document.getElementById("wrapper");
  wrapper.insertAdjacentHTML("beforeend", buildModal(url, alt, full, target));

  const next = document.querySelectorAll(".glide__arrow");
  next.forEach((c) =>
    {c.addEventListener("click", () => {updateModal(glide, c.dataset.glideDir);})
    }
  );

  wrapper.classList.add("is-clipped");
  wrapper.addEventListener("keydown", (event) => {
    updateModal(glide, press(event));
  })
  const modal = document.getElementById(target);

  const modalCloses = getAll(
    ".modal-background, .modal-close, .modal-card-head .delete, .modal-card-foot .button"
  );

  if (modalCloses.length > 0) {
    modalCloses.forEach(function (el) {
      el.addEventListener("click", function () {
        closeModals(rootEl, modal);
      });
    });
  }

  const closeModals = (rootEl, modal) => {
    rootEl.classList.remove("is-clipped");
    wrapper.classList.remove("is-clipped");
    modal.classList.remove("is-active");
    modal.remove();
  };
};

export const initGallery = (selector = ".glide") => {
  const galleries = document.querySelectorAll(selector);
  if (galleries.length) {
    galleries.forEach((slide) => {
      let hash = doHash();
      let id = `gallery-${hash}`;
      slide.setAttribute("id", id);

      let glide = new Glide(`#${id}`, glideOptions);

      glide.mount();

      let childs = slide.querySelectorAll("figure.image > a");
      childs.forEach(
        (c) =>
        c.addEventListener("click", function (e) {
          slideHandler(e, glide);
        })
      );
    });
    initModal();
  }
};
