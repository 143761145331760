import css from "./scss/style.scss";

import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faInstagram,
  faFacebookSquare,
  faFacebook,
  faFacebookF,
  faWhatsappSquare,
  faYoutube,
  faWhatsapp,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

import { initSticky } from "./js/menu-sticky";
import { initBurger } from "./js/burger";
import { initVideoYT } from "./js/videoYT";
import { initGallery } from "./js/gallery";
import { initSlide } from "./js/slider";
import { initNews } from "./js/slider-news";
import { initCopyClipBoard } from "./js/copy-clipboard";
import { initCollapsible } from "./js/collapsible";
import { initVideos, resizeVideos } from "./js/video";
import { initVideoHero } from "./js/video-hero";
import initDropDown from "./js/menu-dropdown";
import { initModal } from "./js/modal";
import { initOrganizationChart } from "./js/organization-chart";
import { initTabs } from "./js/tabs";
import { initBanner } from "./js/banner";

library.add(
  fas,
  faTwitter,
  faFacebook,
  faFacebookF,
  faFacebookSquare,
  faWhatsappSquare,
  faInstagram,
  faYoutube,
  faWhatsapp,
  faLinkedin
);
dom.watch();

function initDelete() {
  (document.querySelectorAll(".notification .delete") || []).forEach(
    ($delete) => {
      const $notification = $delete.parentNode;
      if (sessionStorage.getItem("notification") == "closed") {
        $notification.parentNode.removeChild($notification);
      } else {
        $notification.classList.remove("is-hidden");
        $delete.addEventListener("click", () => {
          $notification.parentNode.removeChild($notification);
          sessionStorage.setItem("notification", "closed");
        });
      }
    }
  );
}

function initSite() {
  initSticky();
  initVideoYT();
  initSlide(".slider");
  initGallery(".gallery");
  initNews(".slider-news");
  initBurger(".navbar-burger");
  initCopyClipBoard();
  initCollapsible();
  initDropDown();
  initDelete();
  initBanner();
  initVideos(".block-video iframe");
  initVideoHero();
  resizeVideos(".block-video iframe");
  initVideos("figure.video iframe");
  resizeVideos("figure.video iframe");
  initModal();
  initOrganizationChart();
  initTabs();

  document.querySelectorAll("a").forEach((link) => {
    link.hostname !== location.hostname &&
      link.setAttribute("target", "_blank");
  });

  function getAll(selector) {
    return Array.prototype.slice.call(document.querySelectorAll(selector), 0);
  }

  const rootEl = document.documentElement;
  const modals = getAll(".modal");
  const modalButtons = getAll(".modal-button");
  const modalCloses = getAll(
    ".modal-background, .modal-close, .modal-card-head .delete, .modal-card-foot .button"
  );

  if (modalButtons.length > 0) {
    modalButtons.forEach(function (el) {
      el.addEventListener("click", function () {
        const targetId = el.dataset.target;
        const target = document.getElementById(targetId);
        rootEl.classList.add("is-clipped");
        target.classList.add("is-active");
      });
    });
  }

  if (modalCloses.length > 0) {
    modalCloses.forEach(function (el) {
      el.addEventListener("click", function () {
        closeModals();
      });
    });
  }

  document.addEventListener("keydown", function (event) {
    const e = event || window.event;
    if (e.keyCode === 27) {
      closeModals();
    }
  });

  function closeModals() {
    rootEl.classList.remove("is-clipped");
    modals.forEach(function (el) {
      el.classList.remove("is-active");
    });
  }
}

document.addEventListener("DOMContentLoaded", initSite, false);
