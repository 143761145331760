export const initVideoHero = () => {

    const videos = document.querySelectorAll(".video");
    const sliders = document.querySelectorAll(".slider");

    let videoList = [];

    const options = {
        threshold: 0.75,
        rootMargin: "0px"
    }

    /* Si el video es visible en el viewport lo enciende, sino lo pausa */
    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {

            videoList.forEach(video => {
                document.addEventListener("visibilitychange", () => {
                    if (document.visibilityState == 'hidden') {
                        video.video.pause();
                    } else if (document.visibilityState == 'visible' && video.isActive) {
                        video.video.play();
                    }
                })
            
                if(!entry.isIntersecting) {
                    video.isActive = false;
                    entry.target.pause();
                    return
                }

                if (entry.isIntersecting && video.id == entry.target.id) {
                    video.isActive = true;
                    entry.target.play();
                }
            })
        })
    }, options);

    /* Si el video está en la slide activa lo enciende, sino lo pausa */
    const mutationObserver = new MutationObserver(entries => {
        entries.forEach(entry => {
            
            const video = entry.target.querySelector('.video')
            if (video) {
                if(!entry.target.classList.contains('glide__slide--active')) {
                    video.pause();
                    return
                }

                video.play();
            }
        })
    })

    /* A cada slide de cada slider le adjunta un observer para detectar cambios en las clases del slide */
    for (let s = 0; s < sliders.length; s++) {
        const slider = sliders[s].querySelectorAll(".glide__slide");
        slider.forEach(slide => {
            mutationObserver.observe(slide, {
                attributes: true,
                attributeFilter: ["class"]
            })
        })
    }

    const doHash = () => Math.random().toString(36).substring(7);
    /* A cada video le adjunta un observer para detectar cuando es visible */
    videos.forEach(video => {

        let hash = doHash();
        let id = `video-user-${hash}`;
        video.setAttribute("id", id);

        const data = {
            video: video,
            isActive: false,
            id: id
        }
        /* A cada video le adjunta un observer para detectar cuando es visible */
        videoList.push(data);
        observer.observe(video);
    })
};