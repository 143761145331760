const API_ENDPOINT = "/search/promotions";
const SEARCH_URL = "/search/";

function renderPromotions(data, element) {
  for (let { query, page, sortOrder } of data.results) {
    let promotion = `<li><a href="${SEARCH_URL}?query=${query}">${page}</a></li>`;
    let fragment = document.createRange().createContextualFragment(promotion);
    element.appendChild(fragment);
  }
}

function initSearchPromotions(target) {
  const element = document.getElementById(target);
  fetch(API_ENDPOINT).then((response) => {
    if (response.ok) {
      response.json().then((data) => renderPromotions(data, element));
    }
  });
}

function batchToggle(selector, pivot, className) {
  const nodeList = document.querySelectorAll(selector);
  [...nodeList].forEach((e) => {
    if (e !== pivot) {
      e.classList.remove(className);
    }
  });
}

function initTarget(name, node) {
  if (name === "finder") {
    initSearchPromotions("search-promotions");

    node.getElementsByClassName("search-input")[0].focus();
  }
}

export function initBurger(selector) {
  // Get all "navbar-burger" elements
  const navbarBurgers = document.querySelectorAll(selector);
  const contextMenu = document.querySelector("body");
  // Check if there are any navbar burgers

  // Add a click event on each of them
  navbarBurgers.forEach((el) => {
    el.addEventListener("click", () => {
      // todos navburger menos 'el' hacemos remove is active
      // todos navloyout menos target
      // Get the target from the "data-target" attribute
      const targetId = el.dataset.target;
      const target = document.getElementById(targetId);

      batchToggle(".navbar-burger", el, "is-active");
      batchToggle(".nav-layout", target, "is-active");

      el.classList.toggle("is-active");
      target.classList.toggle("is-active");

      if (el.classList.contains("is-active")) {
        contextMenu.classList.add("is-context-menu");
        initTarget(target, target);
      } else {
        contextMenu.classList.remove("is-context-menu");

        const removeDropdown = document.querySelectorAll(".has-dropdown");
        removeDropdown.forEach((element) => {
          element.classList.remove("show-dropdown");
        });
      }
    });
  });
}
