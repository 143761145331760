import Glide from "@glidejs/glide";
const doHash = () => Math.random().toString(36).substring(7);


export const initSlide = (selector = ".slider") => {
    const slides = document.querySelectorAll(selector);
    if (slides.length) {
        const glideOptions = {
            type: "carousel",
            perView: 1,
            autoplay: false,
            focusAt: "center",
            animationDuration: 0,
            gap: 0,
            breakpoints: {
                800: {
                    perView: 1,
                },
                480: {
                    perView: 1,
                },
            },
        };

        slides.forEach((element) => {
            let hash = doHash();
            let id = `slider-${hash}`;
            element.setAttribute("id", id);
            let slider = new Glide("#" + id, glideOptions);

            if (element.querySelectorAll('.glide__slide').length > 1) {
                slider.mount();
            }
        });
    }
};
